const translations = {
  'common:copy': 'Copy',
  'common:copied': 'Copied to clipboard!',
  'common:disconnect': 'Disconnect',
  'common:free': 'Free',
  'common:claim': 'Claim',
  'common:buy': 'Buy',
  'common:refresh': 'Refresh',
  'common:cancel': 'Cancel',
  'common:honey': '$HONEY',
  'common:honeyShot': 'Honey Shot',
  'common:close': 'Close',
  'common:lucky': 'Lucky',

  'error:default': 'Oops! Something went wrong.',

  'success:default': 'Success! Thank you.',

  'network:ethereum': 'Ethereum Mainnet',
  'network:bsc': 'Binance Smart Chain',

  'connect:title': 'Connect Your Wallet',
  'connect:button:label': 'Connect with {connector}',

  'footer:learnMore': 'About',
  'footer:powered': 'Powered by',
  'footer:contract': 'Contract',

  'walletPopup:title': 'Your Wallet',

  'unsupportedNetwork:title': 'Wrong Network',
  'unsupportedNetwork:description':
    'Your wallet is connected to an unsupported network. Please change it to: {chainName}',
  'unsupportedNetwork:switch': 'Switch Network',

  'honeyShots:title': 'My Honey Shots',
  'honeyShots:description': 'Not throwing away your shots, are you?',
  'honeyShots:buy': 'Get Honey Shots',
  'honeyShots:details': 'Details',
  'honeyShots:emptyMessage': 'No honey shots',
  'honeyShots:emptyMessage:buy': 'Get Honey Shots',

  'detailsPopup:luckyMessage':
    "Congrats! You just won an NFT in the second draw! Isn't that awesome?",
  'detailsPopup:unluckyMessage':
    'Unfortunately, this time you were not lucky. Thanks for participating in Honey Shots Raffle. Bottoms Up!',

  'drinkPopup:title:idle': 'Fancy a Drink? (#{tokenId})',
  'drinkPopup:title:drinking': 'Bottoms Up!',
  'drinkPopup:drink': 'Yea, Cheers!',
  'drinkPopup:cancel': 'No, Thanks',

  'failurePopup:title': 'Oh no…',
  'failurePopup:description':
    'This Honey Shot turned out to be unlucky. Better luck next time!',
  'failurePopup:buyMore': 'Buy More',

  'consolationPrizePopup:title': 'Congrats!',
  'consolationPrizePopup:description':
    'You just won some delicious $HONEY tokens.',
  'consolationPrizePopup:text':
    'All the prizes won in the raffle are sent manually by the FBM team every Tuesday and Thursday. To see your prize check your wallet after those days.',
  'consolationPrizePopup:honeyWon': '$HONEY Amount',
  'consolationPrizePopup:ok': 'Cool, Thanks!',

  'prizePopup:title': 'Congrats!',
  'prizePopup:description': "You just won the prize! Isn't that awesome?",
  'prizePopup:text':
    'All the prizes won in the raffle are sent manually by the FBM team every Tuesday and Thursday. To see your prize check your wallet after those days.',
  'prizePopup:text:trait':
    'Claiming of traits will be possible for 48h every week starting from Tuesday 12:00 AM UTC only for the whitelisted wallets that won the traits. Please notice to prepare some ETH to cover the gas fee transaction. To enter the TraitSwap app use our official links or visit our website.',
  'prizePopup:ok': 'Cool, Thanks!',

  'honeyShots:drink': 'Drink',
  'honeyShots:empty': 'Empty',

  'honeyShotDetailsPopup:prizeDetails': 'Prize Details',
};

export default translations;
