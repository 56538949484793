import { Chain } from 'wagmi';
import honeyShotsABI from '../abis/honeyShots.abi.json';
import honeyShotsHelperABI from '../abis/honeyShotsHelper.abi.json';

const isTestnet = parseInt(process.env.REACT_APP_USE_TESTNET!, 10) === 1;

const config = {
  environment: process.env.NODE_ENV as 'production' | 'development',
  isTestnet,

  sentry: {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },

  ga: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  },

  traitsCollectionContractAddress:
    process.env.REACT_APP_TRAITS_COLLECTION_CONTRACT_ADDRESS!,

  chains: isTestnet
    ? ([
        {
          id: 97,
          name: 'Binance Smart Chain Testnet',
          network: 'bsctestnet',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: {
            default: 'https://data-seed-prebsc-1-s1.binance.org:8545',
          },
          blockExplorers: {
            default: {
              name: 'BNB Smart Chain Explorer',
              url: 'https://testnet.bscscan.com/',
            },
          },
        },
      ] as Chain[])
    : ([
        {
          id: 56,
          name: 'Binance Smart Chain',
          network: 'bsc',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: {
            default:
              'https://green-omniscient-road.bsc.quiknode.pro/ff0f49bfde75500583412308e5c5dd4e375646d8',
            default1: 'https://bsc-dataseed.binance.org',
            default2: 'https://bsc-dataseed2.binance.org',
            default3: 'https://bsc-dataseed3.binance.org',
          },
          blockExplorers: {
            default: {
              name: 'BNB Smart Chain Explorer',
              url: 'https://bscscan.com',
            },
          },
        },
      ] as Chain[]),

  contracts: {
    honeyShotsContract: {
      address: process.env.REACT_APP_HONEY_SHOTS_CONTRACT_ADDRESS!,
      interface: honeyShotsABI,
    },
    honeyShotsHelperContract: {
      address: process.env.REACT_APP_HONEY_SHOTS_HELPER_CONTRACT_ADDRESS!,
      interface: honeyShotsHelperABI,
    },
  },

  urls: {
    publicUrl: process.env.PUBLIC_URL!,
    apiUrl: process.env.REACT_APP_API_URL!,
    storageUrl: process.env.REACT_APP_STORAGE_URL!,
    nftCollectionUrl: process.env.REACT_APP_NFT_COLLECTION_URL!,
    buyBnbUrl: process.env.REACT_APP_BUY_BNB_URL!,
    learnMoreUrl: process.env.REACT_APP_LEARN_MORE_URL!,
    discordUrl: process.env.REACT_APP_DISCORD_URL!,
    twitterUrl: process.env.REACT_APP_TWITTER_URL!,
  },
};

export default config;
