import React from 'react';

const DiscordIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.0864 6.5429a17.9613 17.9613 0 0 0-4.5093-1.3999c-.1943.347-.4212.8136-.5777 1.1848-1.6813-.2498-3.3471-.2498-4.9975 0-.1564-.3712-.3885-.8378-.5845-1.1848a17.9005 17.9005 0 0 0-4.5127 1.4033C3.0509 10.8064 2.2773 14.9607 2.664 19.056c1.8928 1.3963 3.7271 2.2446 5.5305 2.7996a13.559 13.559 0 0 0 1.1844-1.9271 11.7093 11.7093 0 0 1-1.8652-.8968 9.241 9.241 0 0 0 .4573-.3573c3.5964 1.6617 7.504 1.6617 11.0575 0 .1495.1232.3025.2428.4572.3573a11.6762 11.6762 0 0 1-1.8686.8985 13.4989 13.4989 0 0 0 1.1844 1.9271c1.8051-.555 3.6412-1.4032 5.5339-2.8013.4539-4.7475-.7753-8.8636-3.2491-12.5131ZM9.869 16.5374c-1.0796 0-1.965-.9956-1.965-2.2081 0-1.2124.8665-2.2098 1.965-2.2098 1.0986 0 1.9839.9956 1.965 2.2098.0017 1.2125-.8664 2.2081-1.965 2.2081Zm7.2616 0c-1.0796 0-1.9649-.9956-1.9649-2.2081 0-1.2124.8664-2.2098 1.9649-2.2098 1.0986 0 1.9839.9956 1.965 2.2098 0 1.2125-.8664 2.2081-1.965 2.2081Z"
      fill="currentColor"
    />
  </svg>
);

export default DiscordIcon;
