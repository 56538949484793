import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import useTranslate from '../hooks/useTranslate';
import HoneyShot from '../types/honeyShot';
import BoldSpinner from './BoldSpinner';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import config from '../constants/config';
import ConfettiExplosion from './ConfettiExplosion';
import PrizeDetails from './PrizeDetails';

type PrizePopupProps = Omit<ModalProps, 'children'> & {
  honeyShot: HoneyShot;
};

const PrizePopup = ({ honeyShot, onClose, ...rest }: PrizePopupProps) => {
  const translate = useTranslate();
  const isTraitPrize =
    honeyShot.prize &&
    honeyShot.prize.collectionAddress ===
      config.traitsCollectionContractAddress;

  return (
    <>
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        size="md"
        onClose={onClose}
        {...rest}
      >
        <ModalOverlay flex="1" />

        <ModalContent bg="transparent" mx="0" my="0" px="3" py="3">
          <ModalBody
            p="0"
            paddingInlineStart="0 !important"
            paddingInlineEnd="0 !important"
          >
            <VStack m="5" mt="7" textAlign="center" spacing="1">
              <Heading
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
                m="0 auto"
              >
                {translate('prizePopup:title')}
              </Heading>

              <Text opacity="0.6" maxW="16em" fontSize="sm">
                {translate('prizePopup:description')}
              </Text>
            </VStack>

            <ModalCloseButton />

            {honeyShot.prize ? (
              <>
                <PrizeDetails prize={honeyShot.prize} />

                <Box px="6" pt="6" pb="6">
                  <Box bg="dark.800" borderRadius="lg" textAlign="center">
                    <Text fontSize="sm" px="6" py="4">
                      {translate(
                        isTraitPrize
                          ? 'prizePopup:text:trait'
                          : 'prizePopup:text',
                        {
                          network: translate(
                            `network:${honeyShot.prize.network}`
                          ),
                        }
                      )}
                    </Text>

                    <Button w="full" onClick={onClose} borderTopRadius="none">
                      {translate('prizePopup:ok')}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <Center h="96">
                <BoldSpinner size="lg" />
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <ConfettiExplosion />
    </>
  );
};

export default PrizePopup;
