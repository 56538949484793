import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { getDefaultProvider, providers } from 'ethers';
import { Chain, createClient, WagmiConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import Background from './components/Background';
import Header from './components/Header';
import Layout from './components/Layout';
import config from './constants/config';
import theme from './constants/theme';
import translations from './constants/translations';
import NetworkErrorsContainer from './containers/NetworkErrorsContainer';
import AccountGuard from './guards/AccountGuard';
import ConnectedPage from './pages/ConnectedPage';
import ConnectPage from './pages/ConnectPage';
import HoneyShotsProvider from './providers/HoneyShotsProvider';
import TranslationsProvider from './providers/TranslationsProvider';
import WalletAssetsProvider from './providers/WalletAssetsProvider';

export const injectedConnector = new MetaMaskConnector({
  chains: config.chains,
  options: {
    shimDisconnect: true,
  },
});

export const walletConnectConnector = new WalletConnectConnector({
  chains: config.chains,
  options: {
    qrcode: true,
  },
});

const provider = ({ chainId }: { chainId?: number | undefined }): any => {
  if (!chainId) return getDefaultProvider();

  const chain = config.chains.find(chain => chain.id === chainId) as Chain;
  if (!chain) {
    return getDefaultProvider();
  }

  const providerUrl = chain.rpcUrls.default;
  const jsonRpcProvider = new providers.JsonRpcProvider(providerUrl, chainId);
  return jsonRpcProvider;
};

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [injectedConnector, walletConnectConnector],
  provider,
});

function App() {
  return (
    <ChakraProvider theme={extendTheme(theme)}>
      <WagmiConfig client={wagmiClient}>
        <TranslationsProvider translations={translations}>
          <Background />

          <AccountGuard
            yeyElement={
              <WalletAssetsProvider>
                <HoneyShotsProvider>
                  <Layout>
                    <Header />

                    <ConnectedPage />

                    <NetworkErrorsContainer />
                  </Layout>
                </HoneyShotsProvider>
              </WalletAssetsProvider>
            }
            neyElement={
              <Layout>
                <ConnectPage />
              </Layout>
            }
          />
        </TranslationsProvider>
      </WagmiConfig>
    </ChakraProvider>
  );
}

export default App;
