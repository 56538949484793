import { FunctionComponent, useMemo } from 'react';
import { useAccount, useBalance } from 'wagmi';
import WalletAssetsContext, {
  WalletAssetsContextValue,
} from '../contexts/WalletAssetsContext';

const WalletAssetsProvider: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const { address } = useAccount();

  const { data: balanceData, refetch: readBalance } = useBalance({
    addressOrName: address,
  });

  const contextValue = useMemo<WalletAssetsContextValue>(
    () => ({
      balance: balanceData?.value,
      readBalance,
    }),
    [balanceData, readBalance]
  );

  return (
    <WalletAssetsContext.Provider value={contextValue}>
      {children}
    </WalletAssetsContext.Provider>
  );
};

export default WalletAssetsProvider;
