import { useCallback, useEffect, useMemo } from 'react';
import { useAccount } from 'wagmi';
import HoneyShotsContext, {
  HoneyShotsContextValue,
} from '../contexts/HoneyShotsContext';
import useFetchPromise from '../hooks/useFechPromise';
import useHoneyShotsBalance from '../hooks/useHoneyShotsBalance';
import HoneyShot from '../types/honeyShot';
import honeyShotsApi from '../api/honeyShotsApi';

const HoneyShotsProvider = ({ children }: { children: JSX.Element }) => {
  const { address } = useAccount();
  const [honeyShotsBalance, readHoneyShotsBalance] = useHoneyShotsBalance();
  const [honeyShots, originalFetchHoneyShots, isHoneyShotsLoading] =
    useFetchPromise<HoneyShot[], string>(
      honeyShotsApi.fetchHoneyShots,
      address
    );

  const fetchHoneyShots = useCallback(() => {
    if (address) {
      originalFetchHoneyShots(address);
    }
  }, [address, originalFetchHoneyShots]);

  const contextValue = useMemo<HoneyShotsContextValue>(
    () => ({
      honeyShotsBalance,
      readHoneyShotsBalance,
      honeyShots,
      fetchHoneyShots,
      isLoading: honeyShotsBalance === undefined || isHoneyShotsLoading,
    }),
    [
      honeyShotsBalance,
      readHoneyShotsBalance,
      honeyShots,
      fetchHoneyShots,
      isHoneyShotsLoading,
    ]
  );

  useEffect(() => {
    fetchHoneyShots();
  }, [fetchHoneyShots]);

  return (
    <HoneyShotsContext.Provider value={contextValue}>
      {children}
    </HoneyShotsContext.Provider>
  );
};

export default HoneyShotsProvider;
