import Attribute from './Attribute';
import config from '../constants/config';
import prizeMetadataApi from '../api/prizeMetadataApi';
import useSnackbar from '../hooks/useSnackbar';
import useTranslate from '../hooks/useTranslate';
import { FaRegCopy } from 'react-icons/fa';
import { getShortenedAddress } from '../utils/walletUtils';
import { HoneyShotPrize } from '../types/honeyShot';
import { NftMetadata } from '../types/nft';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCallback, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
  AspectRatio,
  Box,
  BoxProps,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  useClipboard,
  Wrap,
} from '@chakra-ui/react';


type PrizeDetailsProps = BoxProps & {
  prize: HoneyShotPrize;
};

const PrizeDetails = ({ prize, ...rest }: PrizeDetailsProps) => {
  const translate = useTranslate();

  const [metadata, setMetadata] = useState<NftMetadata>();
  const snackbar = useSnackbar();

  const { onCopy: copy } = useClipboard(prize.collectionAddress);

  const imageUrl = `${config.urls.storageUrl}/${prize.collectionAddress}/${prize.tokenId}.png`;

  const fetchPrizeMetadata = useCallback(async (url: string) => {
    try {
      const meta = await prizeMetadataApi.fetchMetadata(url);
      setMetadata(meta);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleCopy = useCallback(() => {
    copy();
    snackbar('success', translate('common:copied'));
  }, [copy, snackbar, translate]);

  useEffect(() => {
    if (prize && prize.metadataUrl) {
      fetchPrizeMetadata(prize.metadataUrl);
    }
  }, [prize, fetchPrizeMetadata]);

  return (
    <Box>
      <AspectRatio ratio={1} bg="dark.600">
        <Image src={imageUrl} w="full" />
      </AspectRatio>

      <Box w="full" pt="6" px="6" overflow="hidden">
        <Heading size="md" mb="1">
          {prize.collectionName} #{prize.tokenId}
        </Heading>

        <Wrap spacing="1.5" opacity="0.8">
          <Text fontSize="xs">{translate(`network:${prize.network}`)}</Text>
          <Text fontSize="xs" opacity="0.6">{` – `}</Text>
          <Flex align="center" onClick={handleCopy} cursor="pointer">
            <Text fontSize="xs">
              <span>{getShortenedAddress(prize.collectionAddress)}</span>
            </Text>
            <Icon as={FaRegCopy} ml="1" w="0.55rem" />
          </Flex>
        </Wrap>

        {metadata && metadata.attributes && (
          <Box mt="3">
            <Swiper
              spaceBetween={8}
              slidesPerView={2.3}
              style={{ overflow: 'visible' }}
            >
              {metadata.attributes.map((attribute, index) => (
                <SwiperSlide key={attribute.trait_type}>
                  <Attribute
                    attribute={attribute}
                    key={index}
                    userSelect="none"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PrizeDetails;
