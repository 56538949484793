import {
  Box,
  Button,
  Container,
  HStack,
  Icon,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import { FaWallet } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import useHoneyShots from '../hooks/useHoneyShots';
import { getShortenedAddress } from '../utils/walletUtils';
import HoneyShotIcon from './HoneyShotIcon';
import Logo from './Logo';
import Panel from './Panel';
import WalletPopup from './WalletPopup';

const Header: React.FC = () => {
  const { address } = useAccount();
  const { honeyShotsBalance } = useHoneyShots();
  const [isWalletDialogVisible, setIsWalletDialogVisible] = useBoolean();

  return (
    <>
      <Panel as="header" px="0" py="4" borderRadius="0">
        <Container>
          <HStack overflow="hidden" spacing="4">
            <Link to="/">
              <Logo w="7em" />
            </Link>

            <HStack justify="flex-end" w="full" spacing="3">
              <Box bg="dark.700" lineHeight="10" px="3" borderRadius="full">
                <HStack w="full" spacing="1">
                  <Icon as={HoneyShotIcon} w="4" h="4" color="primary.500" />
                  <Text>{honeyShotsBalance || '–'}</Text>
                </HStack>
              </Box>

              {address && (
                <Button
                  flex="none"
                  color="bright.50"
                  borderColor="dark.500"
                  _hover={{
                    borderColor: 'primary.500',
                  }}
                  colorScheme="bright"
                  borderRadius="full"
                  variant="outline"
                  size="sm"
                  leftIcon={<Icon as={FaWallet} color="primary.500" />}
                  onClick={setIsWalletDialogVisible.on}
                >
                  {getShortenedAddress(address, 3)}
                </Button>
              )}
            </HStack>
          </HStack>
        </Container>
      </Panel>

      {isWalletDialogVisible && (
        <WalletPopup isOpen onClose={setIsWalletDialogVisible.off} />
      )}
    </>
  );
};

export default Header;
