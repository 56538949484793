import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useAccount, useContractRead } from 'wagmi';
import config from '../constants/config';

const useHoneyShotsBalance = (): [
  balance: number | undefined,
  readBalance: () => void
] => {
  const { address } = useAccount();
  const [balance, setBalance] = useState<number>();

  const { data: balanceData, refetch: readBalance } = useContractRead({
    addressOrName: config.contracts.honeyShotsContract.address,
    contractInterface: config.contracts.honeyShotsContract.interface,
    functionName: 'balanceOf',
    args: [address],
  });

  useEffect(() => {
    if (balanceData) {
      setBalance((balanceData as any as BigNumber).toNumber());
    }
  }, [balanceData]);

  return useMemo(() => [balance, readBalance], [balance, readBalance]);
};

export default useHoneyShotsBalance;
