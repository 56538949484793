import React from 'react';

const TwitterIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M24.4284 6.7216c-.8041.357-1.6684.5974-2.5755.7058.9262-.5546 1.6375-1.4335 1.9717-2.4808a8.9833 8.9833 0 0 1-2.8478 1.0883 4.4766 4.4766 0 0 0-3.2731-1.4162c-2.8951 0-5.0226 2.7012-4.3687 5.5053-3.7257-.1867-7.0298-1.9717-9.242-4.6847-1.1747 2.0154-.6092 4.6519 1.3871 5.987a4.4637 4.4637 0 0 1-2.03-.561c-.0492 2.0774 1.4399 4.0208 3.5964 4.4534a4.4932 4.4932 0 0 1-2.0254.0765c.5701 1.7814 2.2258 3.0773 4.1893 3.1138-1.8852 1.4781-4.2603 2.1383-6.6391 1.8578 1.9844 1.2723 4.3423 2.0145 6.874 2.0145 8.3258 0 13.0297-7.0316 12.7455-13.3383a9.1282 9.1282 0 0 0 2.2376-2.3214Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default TwitterIcon;
