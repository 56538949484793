import HoneyShot from '../types/honeyShot';
import useTranslate from '../hooks/useTranslate';
import {
  Badge,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';

type HoneyShotTileProps = {
  honeyShot: HoneyShot;
  children?: JSX.Element;
};

const HoneyShotTile = ({ honeyShot, children }: HoneyShotTileProps) => {
  const translate = useTranslate();
  const isLucky = honeyShot.honeyPrize || honeyShot.prize;

  return (
    <Flex
      alignItems="center"
      bg="dark.700"
      borderRadius="lg"
      p="3"
      w="full"
      gap="4"
    >
      <HStack spacing="4" w="full">
        <Image
          bg="dark.500"
          src={honeyShot.metadata.image}
          borderRadius="md"
          w="20"
          h="20"
        />

        <VStack align="left" spacing="2">
          <Wrap align="center">
            <Text fontSize="md" fontWeight="semibold">
              {honeyShot.metadata.name}
            </Text>

            {isLucky && (
              <Badge bg="primary.500" color="dark.900">
                {translate('common:lucky')}
              </Badge>
            )}
          </Wrap>

          <Wrap spacing="1">
            {honeyShot.metadata.attributes?.map(
              (attribute, index) => (
                <Badge
                  key={index}
                  bg="dark.500"
                  color="bright.50"
                  fontWeight="normal"
                  textTransform="none"
                >
                  {attribute.value}
                </Badge>
              ),
              []
            )}
          </Wrap>
        </VStack>
      </HStack>

      {children}
    </Flex>
  );
};

export default HoneyShotTile;
