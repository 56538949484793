import axios from 'axios';
import { NftMetadata } from '../types/nft';

export const fetchMetadata = async (url: string): Promise<NftMetadata> => {
  const res = await axios.get<NftMetadata>(url);
  return res.data;
};

const prizeMetadataApi = {
  fetchMetadata,
};

export default prizeMetadataApi;
