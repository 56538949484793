import { Box, HStack, Icon, StackProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import TinyHeading from './TinyHeading';

export type WalletAssetProps = StackProps & {
  title?: React.ReactNode;
  icon: React.FC;
  value?: string | number | JSX.Element;
  link?: { label: string; href: string };
};

const WalletAsset: React.FC<WalletAssetProps> = ({
  title,
  icon,
  value,
  link,
  children,
  ...rest
}) => (
  <HStack
    position="relative"
    w="full"
    bg="dark.800"
    borderRadius="lg"
    justify="space-between"
  >
    <VStack
      align="flex-start"
      px="3"
      py="3"
      gap="0"
      overflow="hidden"
      color="bright.50"
      spacing="1.5"
      {...rest}
    >
      {title && <TinyHeading>{title}</TinyHeading>}

      <HStack overflow="hidden" w="full">
        <Icon as={icon} w="7" h="auto" color="primary.500" />
        <Text fontSize="2xl" lineHeight="6">
          {value ?? '–'}
        </Text>
      </HStack>
    </VStack>

    {link && (
      <Box
        as="a"
        href={link.href}
        target="_blank"
        position="absolute"
        top="0"
        right="0"
        fontSize="xs"
        fontWeight="semibold"
        borderRadius="md"
        px="3"
        py="1"
        bg="dark.800"
        transition="all 0.15s"
        _hover={{
          bg: 'dark.500',
        }}
      >
        {link.label}
      </Box>
    )}

    {children}
  </HStack>
);

export default WalletAsset;
