import { BigNumber } from '@ethersproject/bignumber';
import { formatEther } from 'ethers/lib/utils';

export const formatEtherBalance = (value: BigNumber, decimals = 4) => {
  const result = Number(formatEther(value));
  return parseFloat(result.toFixed(decimals));
};

export const generateRandomInteger = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);
