import React from 'react';

const HoneyIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.2443 26.0006C16.7221 25.2236 15.2042 24.4416 13.6758 23.6776C13.2064 23.4425 12.9948 23.1154 13.0001 22.6199C13.0152 21.1894 13.0116 19.759 13.0001 18.3286C13.0001 17.8746 13.194 17.5605 13.6271 17.3416C15.0581 16.6202 16.4853 15.8917 17.9087 15.1562C18.3019 14.9536 18.6729 14.9462 19.067 15.1488C20.5016 15.886 21.9389 16.6183 23.3788 17.3457C23.5738 17.4329 23.7366 17.5708 23.8468 17.7425C23.9571 17.9141 24.0099 18.1117 23.9987 18.3107C23.9987 19.7623 23.9987 21.2133 23.9987 22.6638C23.9987 23.1138 23.7976 23.4311 23.3646 23.6508C21.8317 24.427 20.305 25.2138 18.7766 25.9974L18.2443 26.0006Z"
      fill="currentColor"
    />
    <path
      d="M16.999 9.97374C16.999 10.7451 16.9911 11.5155 16.999 12.2859C17.0061 12.7881 16.8033 13.1446 16.3587 13.3882C14.931 14.1719 13.5033 14.9614 12.0757 15.7569C11.9 15.8627 11.6985 15.918 11.4932 15.9168C11.288 15.9155 11.0871 15.8578 10.9128 15.7498C9.49574 14.9555 8.07868 14.1692 6.6572 13.3944C6.19843 13.1446 5.9965 12.789 6.00005 12.2718C6.01126 10.7298 6.01126 9.18831 6.00005 7.64744C6.00005 7.13029 6.19666 6.77375 6.65543 6.524C8.07957 5.74827 9.49574 4.95931 10.911 4.16857C11.0849 4.05994 11.2856 4.00159 11.4908 4.00003C11.6959 3.99847 11.8975 4.05377 12.073 4.15975C13.5095 4.95754 14.9478 5.7518 16.3879 6.54253C16.8077 6.77287 17.0079 7.11705 16.9999 7.59184C16.9946 8.38875 16.999 9.18125 16.999 9.97374Z"
      fill="currentColor"
    />
  </svg>
);

export default HoneyIcon;
