import {
  Box,
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import config from '../constants/config';
import useTranslate from '../hooks/useTranslate';
import HoneyShot from '../types/honeyShot';
import { formatEtherBalance } from '../utils/numberUtils';
import ConfettiExplosion from './ConfettiExplosion';
import HoneyIcon from './HoneyIcon';
import TinyHeading from './TinyHeading';
import WalletAsset from './WalletAsset';

type ConsolationPrizePopupProps = Omit<ModalProps, 'children'> & {
  honeyShot: HoneyShot;
};

const ConsolationPrizePopup = ({
  honeyShot,
  onClose,
  ...rest
}: ConsolationPrizePopupProps) => {
  const translate = useTranslate();

  return (
    <>
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        size="md"
        onClose={onClose}
        {...rest}
      >
        <ModalOverlay />

        <ModalContent bg="transparent" mx="0" my="0" px="3" py="3">
          <ModalBody
            p="0"
            paddingInlineStart="0 !important"
            paddingInlineEnd="0 !important"
            overflow="hidden"
          >
            <Image
              src={`${config.urls.publicUrl}/consolation-prize-illustration.png`}
              w="180px"
              h="auto"
              m="0 auto"
              mt="9"
              borderRadius="full"
            />

            <VStack mx="6" my="5" textAlign="center" spacing="1">
              <Heading
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
                m="0 auto"
              >
                {translate('consolationPrizePopup:title')}
              </Heading>

              <Text opacity="0.6" maxW="18em" fontSize="sm">
                {translate('consolationPrizePopup:description', {
                  honeyAmount: formatEtherBalance(
                    BigNumber.from(honeyShot.honeyPrize)
                  ),
                })}
              </Text>
            </VStack>

            <ModalCloseButton />

            <Box mx="6" mb="6">
              <WalletAsset
                p="4"
                icon={HoneyIcon}
                value={formatEtherBalance(BigNumber.from(honeyShot.honeyPrize))}
              >
                <TinyHeading pr="5">{translate('common:honey')}</TinyHeading>
              </WalletAsset>
            </Box>

            <Box px="6" pb="6">
              <Box bg="dark.800" borderRadius="lg" textAlign="center">
                <Text fontSize="sm" px="6" py="4">
                  {translate('consolationPrizePopup:text')}
                </Text>
                <Button w="full" onClick={onClose} borderTopRadius="none">
                  {translate('consolationPrizePopup:ok')}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ConfettiExplosion delay={250} />
    </>
  );
};

export default ConsolationPrizePopup;
