import React from 'react';

type TranslationsContextValue = {
  translate: (key: string, values?: { [key: string]: any }) => string;
  translations: Record<string, string>;
};

// @ts-ignore
const TranslationsContext = React.createContext<TranslationsContextValue>();

TranslationsContext.displayName = 'TranslationsContext';

export default TranslationsContext;
