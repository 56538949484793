import { Box, BoxProps, useTimeout } from '@chakra-ui/react';
import { useState } from 'react';
import Confetti from 'react-dom-confetti';

const defaultConfettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 35,
  elementCount: 60,
  dragFriction: 0.15,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '600px',
  colors: ['#FF0035', '#F2AF4A', '#5FAD41', '#4A4651', '#1789FC'],
};

interface ConfettiConfig {
  angle?: number;
  spread?: number;
  width?: string;
  height?: string;
  duration?: number;
  dragFriction?: number;
  stagger?: number;
  startVelocity?: number;
  elementCount?: number;
  decay?: number;
  colors?: string[];
  random?: () => number;
}

type ConfettiExplosionProps = BoxProps & {
  delay?: number;
  config?: ConfettiConfig;
};

const ConfettiExplosion = ({
  delay = 500,
  config = {},
  ...rest
}: ConfettiExplosionProps) => {
  const [isActive, setIsActive] = useState(false);

  useTimeout(() => {
    setIsActive(true);
  }, delay);

  return (
    <Box
      position="fixed"
      top="10%"
      left="50%"
      zIndex="toast"
      pointerEvents="none"
      {...rest}
    >
      <Confetti
        active={isActive}
        config={{ ...defaultConfettiConfig, ...config }}
      />
    </Box>
  );
};

export default ConfettiExplosion;
