import { Heading, HeadingProps } from '@chakra-ui/react';

type TinyHeadingProps = HeadingProps;

const TinyHeading = (props: TinyHeadingProps) => (
  <Heading
    fontSize="xs"
    fontWeight="semibold"
    opacity="0.6"
    textTransform="uppercase"
    {...props}
  />
);

export default TinyHeading;
