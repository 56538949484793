import { BigNumber } from 'ethers';
import useTranslate from '../hooks/useTranslate';
import { formatEtherBalance } from '../utils/numberUtils';

type EtherAmountProps = {
  amount?: BigNumber;
  asPrice?: boolean;
};

const EtherAmount = ({ amount, asPrice = false }: EtherAmountProps) => {
  const translate = useTranslate();

  return (
    <>
      {amount
        ? asPrice && amount.eq('0')
          ? translate('common:free')
          : formatEtherBalance(amount)
        : '–'}
    </>
  );
};

export default EtherAmount;
