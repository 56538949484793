import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import { useAccount } from 'wagmi';
import honeyShotsApi from '../api/honeyShotsApi';
import config from '../constants/config';
import useSnackbar from '../hooks/useSnackbar';
import useTranslate from '../hooks/useTranslate';
import HoneyShot from '../types/honeyShot';

type DrinkHoneyShotPopupProps = Omit<ModalProps, 'children'> & {
  honeyShot: HoneyShot;
  onSuccess: (honeyShot: HoneyShot) => void;
};

const DrinkHoneyShotPopup = ({
  honeyShot,
  onSuccess,
  onClose,
  ...rest
}: DrinkHoneyShotPopupProps) => {
  const { address } = useAccount();
  const translate = useTranslate();
  const snackbar = useSnackbar();
  const cheersVideo = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useBoolean(false);
  const [drunkHoneyShot, setDrunkHoneyShot] = useState<HoneyShot>();

  const handleDrink = useCallback(async () => {
    cheersVideo?.current?.play();
    setIsLoading.on();

    try {
      const drunkHoneyShot = await honeyShotsApi.drinkHoneyShot(
        honeyShot.tokenId,
        address!
      );

      setDrunkHoneyShot(drunkHoneyShot);
      // TODO
      // onSuccess(drunkHoneyShot);
    } catch (error: any) {
      snackbar('error', error?.message || translate('error:default'));
    }
  }, [
    cheersVideo,
    honeyShot,
    address,
    setIsLoading,
    snackbar,
    translate,
    // onSuccess,
  ]);

  const handleVideoEnded = useCallback(() => {
    if (drunkHoneyShot) {
      onSuccess(drunkHoneyShot);
    }
  }, [onSuccess, drunkHoneyShot]);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="md"
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay />

      <ModalContent bg="transparent" mx="0" my="0" px="3" py="3">
        <ModalBody
          p="0"
          paddingInlineStart="0 !important"
          paddingInlineEnd="0 !important"
          overflow="hidden"
        >
          <Box m="6" mt="8">
            <Heading
              fontSize="2xl"
              fontWeight="bold"
              textAlign="center"
              m="0 auto"
            >
              {isLoading
                ? translate('drinkPopup:title:drinking', {
                    tokenId: honeyShot.tokenId,
                  })
                : translate('drinkPopup:title:idle', {
                    tokenId: honeyShot.tokenId,
                  })}
            </Heading>
          </Box>

          {!isLoading && <ModalCloseButton />}

          <AspectRatio bg="dark.600" ratio={1} w="full">
            <video
              src={`${config.urls.publicUrl}/cheers.mp4`}
              ref={cheersVideo}
              onEnded={handleVideoEnded}
            />
          </AspectRatio>

          <VStack p="6" spacing="3">
            <Button
              w="full"
              onClick={handleDrink}
              colorScheme={!isLoading ? 'primary' : 'dark'}
              isLoading={isLoading}
            >
              {translate('drinkPopup:drink', { tokenId: honeyShot.tokenId })}
            </Button>

            {!isLoading && (
              <Button
                w="full"
                colorScheme="dark"
                variant="outline"
                onClick={onClose}
              >
                {translate('drinkPopup:cancel', {
                  tokenId: honeyShot.tokenId,
                })}
              </Button>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DrinkHoneyShotPopup;
