import React from 'react';

const TraitSwapLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="429"
    height="132"
    viewBox="0 0 429 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.5001 31.86V44.62L6.13013 60.11V44.8L18.8001 38.37L6.13013 31.94V16.37L33.5001 31.86ZM62.5401 31.86V44.62L35.1701 60.11V44.8L47.8401 38.37L35.1701 31.94V16.37L62.5401 31.86Z"
      fill="currentColor"
    />
    <path
      d="M86.5699 16.81H57.96V0H136.47V16.81H107.86V60.73H86.5599V16.81H86.5699Z"
      fill="currentColor"
    />
    <path
      d="M144.22 0H198.17C205.5 0 211.27 1.72 215.46 5.15C219.65 8.58 221.75 13.61 221.75 20.24C221.75 25.05 220.68 29.07 218.54 32.3C216.4 35.53 213.36 37.88 209.43 39.34L223.34 60.73H199.31L186.72 41.37H165.51V60.73H144.21V0H144.22ZM200.38 20.95V19.8C200.38 18.16 199.91 16.9 198.97 16.02C198.03 15.14 196.24 14.7 193.6 14.7H165.52V26.32H193.6C196.18 26.32 197.96 25.84 198.92 24.87C199.89 23.9 200.38 22.59 200.38 20.95Z"
      fill="currentColor"
    />
    <path
      d="M256.27 0H284.44L315.42 60.73H293.5L287.34 48.5H251.43L245.27 60.73H225.11L256.27 0ZM258.91 33.71H279.95L269.48 12.94L258.91 33.71Z"
      fill="currentColor"
    />
    <path d="M321.14 0H342.44V60.73H321.14V0Z" fill="currentColor" />
    <path
      d="M378.79 16.81H350.18V0H428.69V16.81H400.09V60.73H378.79V16.81V16.81Z"
      fill="currentColor"
    />
    <path
      d="M0.0500488 110.17H21.4401C22.1401 112.99 23.95 114.81 26.85 115.63C29.75 116.45 34.17 116.86 40.1 116.86C46.2 116.86 50.7401 116.52 53.7001 115.85C56.6601 115.18 58.1501 113.9 58.1501 112.02C58.1501 109.5 55.17 108.19 49.22 108.1C43.26 108.01 36.7201 107.7 29.5901 107.18C22.4601 106.66 15.9201 105.17 9.96008 102.73C4.00008 100.3 1.03009 95.5 1.03009 88.34C1.03009 84.76 1.90007 81.69 3.63007 79.14C5.36007 76.59 7.86005 74.52 11.11 72.93C14.37 71.35 18.39 70.19 23.17 69.45C27.95 68.72 33.3401 68.35 39.3201 68.35C51.5201 68.35 60.8801 69.95 67.4001 73.15C73.9101 76.35 77.5501 81.64 78.3101 89.04H56.8301C56.2401 86.69 54.4501 85.14 51.4601 84.38C48.4701 83.62 44.4201 83.24 39.3101 83.24C32.8001 83.24 28.3101 83.5 25.8401 84.03C23.3801 84.56 22.1401 85.82 22.1401 87.81C22.1401 90.22 25.1301 91.48 31.1201 91.59C37.1101 91.71 43.6501 92.03 50.7501 92.56C57.8501 93.09 64.3901 94.51 70.3801 96.83C76.3701 99.15 79.36 103.68 79.36 110.43C79.36 118.06 76.2001 123.52 69.9001 126.8C63.5901 130.09 53.66 131.73 40.11 131.73C33.07 131.73 27.07 131.3 22.11 130.45C17.15 129.6 13.1001 128.28 9.96008 126.49C6.82008 124.7 4.46007 122.46 2.88007 119.76C1.28007 117.06 0.340049 113.86 0.0500488 110.17Z"
      fill="currentColor"
    />
    <path
      d="M81.99 69.6801H103.11L114.55 112.37L126.78 69.6801H153.8L165.77 112.46L177.21 69.6801H195.78L179.41 130.41H151.33L139.1 86.84L126.51 130.41H98.43L81.99 69.6801Z"
      fill="currentColor"
    />
    <path
      d="M221.33 69.6801H249.5L280.48 130.41H258.56L252.4 118.18H216.49L210.33 130.41H190.17L221.33 69.6801ZM223.97 103.39H245L234.53 82.6201L223.97 103.39Z"
      fill="currentColor"
    />
    <path
      d="M286.19 69.6801H339C347.21 69.6801 353.29 71.6001 357.22 75.4501C361.15 79.2901 363.12 84.6201 363.12 91.4201C363.12 98.7001 361.15 104.35 357.22 108.36C353.29 112.38 347.21 114.39 339 114.39H307.49V130.41H286.19V69.6801ZM341.73 92.8301V90.8001C341.73 89.3301 341.25 88.0701 340.28 87.0201C339.31 85.9701 337.74 85.4401 335.57 85.4401H307.49V98.6401H335.57C337.92 98.6401 339.53 98.1101 340.41 97.0601C341.29 96.0001 341.73 94.5901 341.73 92.8301Z"
      fill="currentColor"
    />
    <path
      d="M398.15 101.54V114.3L370.78 129.79V114.47L383.45 108.04L370.78 101.61V86.05L398.15 101.54ZM427.19 101.54V114.3L399.82 129.79V114.47L412.49 108.04L399.82 101.61V86.05L427.19 101.54Z"
      fill="currentColor"
    />
  </svg>
);

export default TraitSwapLogo;
