import React from 'react';

const BnbIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#bnbIconClippingPath)">
      <path
        d="M7.95397 15.0002L5.04632 17.9037L2.14282 15.0002L5.04632 12.0967L7.95397 15.0002Z"
        fill="currentColor"
      />
      <path
        d="M14.9999 7.94867L19.9768 12.9283L22.8803 10.0234L14.9999 2.14307L7.11963 10.0234L10.0231 12.9283L14.9999 7.94867Z"
        fill="currentColor"
      />
      <path
        d="M24.955 12.0967L22.0515 15.0002L24.955 17.9037L27.8571 15.0002L24.955 12.0967Z"
        fill="currentColor"
      />
      <path
        d="M14.9999 22.0518L10.0231 17.075L7.11963 19.9771L14.9999 27.8574L22.8803 19.9771L19.9768 17.075L14.9999 22.0518Z"
        fill="currentColor"
      />
      <path
        d="M14.9988 12.0966L12.0951 15.0002L14.9988 17.9039L17.9025 15.0002L14.9988 12.0966Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="bnbIconClippingPath">
        <rect
          width="25.7143"
          height="25.7143"
          fill="white"
          transform="translate(2.14282 2.14307)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BnbIcon;
