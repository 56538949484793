export const substitute = (
  str: string,
  values?: { [key: string]: any }
): string => {
  let result = str || '';

  if (values) {
    for (const [valueKey, value] of Object.entries(values)) {
      result = result.replace(new RegExp(`{${valueKey}}`, 'g'), value);
    }
  }

  return result;
};

export const trimPrice = (price: string) => price.replace(/\.?0+$/, '');

export const capitalize = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
