import { Icon, StackProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import useTranslate from '../hooks/useTranslate';

export interface EmptyStateProps extends StackProps {
  label?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = ({ label, ...rest }) => {
  const translate = useTranslate();

  return (
    <VStack align="center" spacing="3" textAlign="center" {...rest}>
      <Icon as={FaSearch} w="6" h="auto" />
      <Text fontSize="sm" maxW="28" lineHeight="short">
        {label || translate('common:noResults')}
      </Text>
    </VStack>
  );
};

export default EmptyState;
