import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import config from '../constants/config';
import useTranslate from '../hooks/useTranslate';
import HoneyShot from '../types/honeyShot';

type FailurePopupProps = Omit<ModalProps, 'children'> & {
  honeyShot: HoneyShot;
};

const FailurePopup = ({ honeyShot, onClose, ...rest }: FailurePopupProps) => {
  const translate = useTranslate();

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay />

      <ModalContent bg="transparent" mx="0" my="0" px="3" py="3">
        <ModalBody
          p="0"
          paddingInlineStart="0 !important"
          paddingInlineEnd="0 !important"
          overflow="hidden"
        >
          <Image
            src={`${config.urls.publicUrl}/failure-illustration.png`}
            w="180px"
            h="auto"
            m="0 auto"
            mt="9"
            borderRadius="full"
          />

          <VStack m="6" mt="5" textAlign="center" spacing="1">
            <Heading
              fontSize="3xl"
              fontWeight="bold"
              textAlign="center"
              m="0 auto"
            >
              {translate('failurePopup:title')}
            </Heading>

            <Text opacity="0.6" maxW="18em" fontSize="sm">
              {translate('failurePopup:description')}
            </Text>
          </VStack>

          <ModalCloseButton />

          <Box px="6" pb="6">
            <Button
              w="full"
              onClick={onClose}
              colorScheme="primary"
              rightIcon={<Icon as={FaExternalLinkAlt} w="3" />}
            >
              {translate('failurePopup:buyMore')}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FailurePopup;
