import HoneyIcon from './HoneyIcon';
import HoneyShot from '../types/honeyShot';
import PrizeDetails from './PrizeDetails';
import TinyHeading from './TinyHeading';
import useTranslate from '../hooks/useTranslate';
import WalletAsset from './WalletAsset';
import { BigNumber } from 'ethers';
import { formatEtherBalance } from '../utils/numberUtils';
import {
  Badge,
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';

type HoneyShotDetailsPopupProps = Omit<ModalProps, 'children'> & {
  honeyShot: HoneyShot;
};

const HoneyShotDetailsPopup = ({
  honeyShot,
  onClose,
  ...rest
}: HoneyShotDetailsPopupProps) => {
  const translate = useTranslate();

  const isLucky = honeyShot.honeyPrize || honeyShot.prize;

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="md"
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay flex="1" />

      <ModalContent bg="transparent" mx="0" my="0" px="3" py="3">
        <ModalBody
          p="0"
          paddingInlineStart="0 !important"
          paddingInlineEnd="0 !important"
        >
          <VStack mt="10" textAlign="center" spacing="2">
            <Heading
              fontSize="3xl"
              fontWeight="bold"
              textAlign="center"
              m="0 auto"
            >
              {translate('common:honeyShot')} #{honeyShot.tokenId}
            </Heading>

            <Wrap spacing="1">
              {honeyShot.metadata.attributes?.map(
                (attribute, index) => (
                  <Badge
                    key={index}
                    bg="dark.700"
                    color="bright.50"
                    textTransform="none"
                    fontWeight="normal"
                    px="2.5"
                    py="1"
                  >
                    {attribute.value}
                  </Badge>
                ),
                []
              )}
            </Wrap>
          </VStack>

          <ModalCloseButton />

          {isLucky && (
            <Box mt="6">
              {honeyShot.prize && (
                <Box
                  mx="6"
                  bg="dark.800"
                  borderRadius="lg"
                  overflow="hidden"
                  pb="6"
                >
                  <PrizeDetails prize={honeyShot.prize} />{' '}
                </Box>
              )}

              {honeyShot.honeyPrize && (
                <Box mx="6">
                  <WalletAsset
                    p="4"
                    icon={HoneyIcon}
                    value={formatEtherBalance(
                      BigNumber.from(honeyShot.honeyPrize)
                    )}
                  >
                    <TinyHeading pr="5">
                      {translate('common:honey')}
                    </TinyHeading>
                  </WalletAsset>
                </Box>
              )}
            </Box>
          )}

          <Box px="6" pb="6" mt="6">
            <Box bg="dark.800" borderRadius="lg" textAlign="center">
              {isLucky ? (
                <Box px="6" py="4" fontSize="sm">
                  <Text>{translate('detailsPopup:luckyMessage')}</Text>
                  <Text fontSize="xs" opacity="0.6" mt="2">
                    {translate('consolationPrizePopup:text')}
                  </Text>
                </Box>
              ) : (
                <Text fontSize="sm" px="6" py="4">
                  {translate('detailsPopup:unluckyMessage')}
                </Text>
              )}

              <Button
                w="full"
                colorScheme="dark"
                onClick={onClose}
                borderTopRadius="none"
              >
                {translate('common:close')}
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HoneyShotDetailsPopup;
