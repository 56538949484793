import React from 'react';

const HoneyShotIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_309_3386)">
      <path
        d="M7.02002 6.5964H22.7015C23.6541 6.5964 24.4265 5.79128 24.4265 4.7982C24.4265 3.80512 23.6541 3 22.7015 3H7.02002C6.06434 3 5.29199 3.80512 5.29199 4.7982C5.29199 5.79128 6.06434 6.5964 7.02002 6.5964Z"
        fill="currentColor"
      />
      <path
        d="M23.4102 10.412C23.1578 9.92953 22.6649 9.63818 22.136 9.63818H18.3944V9.64132C18.3944 9.64132 17.1262 9.64132 17.1262 10.9633C17.1262 10.9759 17.1232 10.9821 17.1232 10.9947V14.0115C17.1232 14.8354 16.4801 15.5058 15.6897 15.5058C14.8993 15.5058 14.2562 14.8354 14.2562 14.0115V11.9282C14.2562 11.1325 13.559 11.1137 13.559 11.1137C12.9309 11.1137 12.8738 11.8812 12.8708 11.9408V16.336C12.8708 17.5484 11.9271 18.5321 10.7641 18.5321C9.60105 18.5321 8.6574 17.5484 8.6574 16.336V10.3806C8.62434 9.73843 8.07738 9.65071 7.93013 9.64132H7.87002C7.3441 9.64132 6.84823 9.93266 6.59579 10.4151C5.58302 12.3323 5 14.6161 5 17.0722C5 20.8942 6.41548 24.3089 8.63636 26.5676C8.90984 26.8464 9.27648 26.9999 9.66116 26.9999H20.3418C20.7235 26.9999 21.0932 26.8464 21.3666 26.5676C23.5875 24.3089 25.003 20.8942 25.003 17.0722C25 14.6161 24.417 12.3323 23.4102 10.412Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_309_3386">
        <rect width="20" height="24" fill="white" transform="translate(5 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default HoneyShotIcon;
