import axios from 'axios';
import config from '../constants/config';
import HoneyShot from '../types/honeyShot';

export const fetchHoneyShots = async (
  walletAddress: string
): Promise<HoneyShot[]> => {
  const res = await axios.get<HoneyShot[]>(
    `/fetch-honey-shots/${walletAddress}`,
    {
      baseURL: config.urls.apiUrl,
    }
  );

  return res.data.map(hs => {
    return {
      ...hs,
      honeyPrize: undefined,
    };
  });
};

export const drinkHoneyShot = async (
  tokenId: number,
  walletAddress: string
): Promise<HoneyShot> => {
  const res = await axios.post<HoneyShot>(
    `/drink-honey-shot`,
    {
      tokenId,
      walletAddress,
    },
    {
      baseURL: config.urls.apiUrl,
    }
  );

  return res.data;
};

const honeyShotsApi = {
  fetchHoneyShots,
  drinkHoneyShot,
};

export default honeyShotsApi;
