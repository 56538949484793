import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  CloseButton,
  HStack,
} from '@chakra-ui/react';
import React from 'react';

export interface SnackbarAlertProps {
  message: string;
  status: AlertProps['status'];
  onClose: () => void;
}

const SnackbarAlert: React.FC<SnackbarAlertProps> = ({
  message,
  status,
  onClose,
}) => (
  <Alert
    status={status}
    bg="dark.900"
    color="bright.50"
    rounded="lg"
    px="3"
    py="2"
  >
    <AlertIcon />
    <HStack w="full" justify="space-between">
      <AlertDescription>{message}</AlertDescription>
      <CloseButton ml="2" onClick={onClose} />
    </HStack>
  </Alert>
);

export default SnackbarAlert;
