import { Center, Container, VStack } from '@chakra-ui/react';
import BoldSpinner from '../components/BoldSpinner';
import HoneyShotsContainer from '../containers/HoneyShotsContainer';
import useHoneyShots from '../hooks/useHoneyShots';

const ConnectedPage = () => {
  const { isLoading } = useHoneyShots();

  return (
    <Center flex="1">
      <Container maxW="lg">
        <VStack spacing="8" pt="16" pb="16" w="full">
          {isLoading ? (
            <Center h="sm">
              <BoldSpinner size="xl" />
            </Center>
          ) : (
            <HoneyShotsContainer />
          )}
        </VStack>
      </Container>
    </Center>
  );
};

export default ConnectedPage;
