import {
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import config from '../constants/config';
import useTranslate from '../hooks/useTranslate';
import DiscordIcon from './DiscordIcon';
import BnbIcon from './BnbIcon';
import Panel from './Panel';
import TraitSwapLogo from './TraitSwapLogo';
import TwitterIcon from './TwitterIcon';

const Footer: React.FC = () => {
  const translate = useTranslate();

  return (
    <>
      <Panel as="footer" mt="auto" bg="dark.900" px="0" borderRadius="none">
        <Container>
          <HStack justify="space-between">
            <HStack spacing="5">
              <Link
                href={config.urls.nftCollectionUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={BnbIcon} w="6" h="auto" />
              </Link>

              <Link
                href={config.urls.discordUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={DiscordIcon} w="7" h="auto" />
              </Link>

              <Link
                href={config.urls.twitterUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={TwitterIcon} w="7" h="auto" />
              </Link>
            </HStack>

            <HStack justify="space-between" spacing="4">
              <Link href={config.urls.learnMoreUrl} target="_blank">
                <Button as="span" variant="link" fontSize="sm">
                  {translate('footer:learnMore')}
                </Button>
              </Link>

              <Link
                href={`${
                  config.chains![0].blockExplorers?.default.url
                }/address/${config.contracts.honeyShotsContract.address}`}
                target="_blank"
              >
                <Button as="span" variant="link" fontSize="sm">
                  {translate('footer:contract')}
                </Button>
              </Link>
            </HStack>
          </HStack>

          <Divider my="2.5" />

          <LinkBox as={Center}>
            <Text fontSize="sm" fontWeight="semibold" mr="1.5">
              {translate('footer:powered')}
            </Text>
            <LinkOverlay href="//traitswap.com" target="_blank">
              <TraitSwapLogo width="65" height="auto" />
            </LinkOverlay>
          </LinkBox>
        </Container>
      </Panel>
    </>
  );
};

export default Footer;
