import React from 'react';
import HoneyShot from '../types/honeyShot';

export type HoneyShotsContextValue = {
  isLoading: boolean;
  honeyShotsBalance?: number;
  readHoneyShotsBalance: () => void;
  honeyShots?: HoneyShot[];
  fetchHoneyShots: () => void;
};

// @ts-ignore
const HoneyShotsContext = React.createContext<HoneyShotsContextValue>();

HoneyShotsContext.displayName = 'HoneyShotsContext';

export default HoneyShotsContext;
