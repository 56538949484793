import config from '../constants/config';
import ConsolationPrizePopup from '../components/ConsolationPrizePopup';
import DrinkHoneyShotPopup from '../components/DrinkHoneyShotPopup';
import EmptyState from '../components/EmptyState';
import FailurePopup from '../components/FailurePopup';
import HoneyShot from '../types/honeyShot';
import HoneyShotDetailsPopup from '../components/HoneyShotDetailsPopup';
import HoneyShotTile from '../components/HoneyShotTile';
import Panel from '../components/Panel';
import PrizePopup from '../components/PrizePopup';
import useHoneyShots from '../hooks/useHoneyShots';
import useTranslate from '../hooks/useTranslate';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Icon,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';

const HoneyShotsContainer = () => {
  const translate = useTranslate();
  const { honeyShots, fetchHoneyShots } = useHoneyShots();

  const [honeyShotToDrink, setHoneyShotToDrink] = useState<HoneyShot>();
  const [drunkHoneyShot, setDrunkHoneyShot] = useState<HoneyShot>();

  const [isDrinkDialogVisible, setIsDrinkDialogVisible] = useBoolean(false);
  const [isPrizePopupVisible, setIsPrizePopupVisible] = useBoolean(false);
  const [isFailurePopupVisible, setIsFailurePopupVisible] = useBoolean(false);
  const [isConsolationPrizePopupVisible, setIsConsolationPrizePopupVisible] =
    useBoolean(false);
  const [isHoneyShotDetailsPopupVisible, setIsHoneyShotDetailsPopupVisible] =
    useBoolean(false);

  const hasHoneyShots = Boolean(honeyShots?.length);

  const handleDrink = useCallback(
    (honeyShot: HoneyShot) => {
      setHoneyShotToDrink(honeyShot);
      setIsDrinkDialogVisible.on();
    },
    [setIsDrinkDialogVisible]
  );

  const handleSeeDetails = useCallback(
    (honeyShot: HoneyShot) => {
      setDrunkHoneyShot(honeyShot);
      setIsHoneyShotDetailsPopupVisible.on();
    },
    [setIsHoneyShotDetailsPopupVisible]
  );

  const handleClosePopups = useCallback(() => {
    fetchHoneyShots();

    setIsPrizePopupVisible.off();
    setIsFailurePopupVisible.off();
    setIsConsolationPrizePopupVisible.off();
    setIsDrinkDialogVisible.off();
  }, [
    fetchHoneyShots,
    setIsPrizePopupVisible,
    setIsFailurePopupVisible,
    setIsConsolationPrizePopupVisible,
    setIsDrinkDialogVisible,
  ]);

  const handleDrinkSuccess = useCallback(
    (honeyShot: HoneyShot) => {
      setDrunkHoneyShot(honeyShot);
      setIsDrinkDialogVisible.off();

      if (honeyShot.prize) {
        setIsPrizePopupVisible.on();
      } else if (honeyShot.honeyPrize) {
        setIsConsolationPrizePopupVisible.on();
      } else {
        setIsFailurePopupVisible.on();
      }
    },
    [
      setIsDrinkDialogVisible,
      setIsPrizePopupVisible,
      setIsFailurePopupVisible,
      setIsConsolationPrizePopupVisible,
    ]
  );

  const renderHoneyShot = (honeyShot: HoneyShot) => {
    return (
      <HoneyShotTile key={honeyShot.tokenId} honeyShot={honeyShot}>
        <Box ml="auto">
          {honeyShot.status === 'full' ? (
            <Button size="sm" onClick={() => handleDrink(honeyShot)}>
              {translate('honeyShots:drink')}
            </Button>
          ) : (
            <Button
              size="sm"
              colorScheme="dark"
              isDisabled={false}
              onClick={() => handleSeeDetails(honeyShot)}
            >
              {translate('honeyShots:details')}
            </Button>
          )}
        </Box>
      </HoneyShotTile>
    );
  };

  return (
    <>
      <Panel>
        <VStack
          mb="5"
          mx="3"
          mt="6"
          align="left"
          spacing="0.5"
          textAlign="center"
        >
          <Heading fontSize="2xl">{translate('honeyShots:title')}</Heading>

          <Text fontSize="sm" opacity="0.6">
            {translate('honeyShots:description')}
          </Text>
        </VStack>

        {hasHoneyShots ? (
          <VStack spacing="1">{honeyShots?.map(renderHoneyShot)}</VStack>
        ) : (
          <Box py="8">
            <EmptyState
              label={translate('honeyShots:emptyMessage')}
              opacity="0.6"
            />
          </Box>
        )}

        <Box mt="6" mb="3" mx="3">
          <Button
            as="a"
            href={config.urls.nftCollectionUrl}
            target="_blank"
            w="full"
            variant="outline"
            rightIcon={<Icon as={FaExternalLinkAlt} />}
          >
            {translate('honeyShots:buy')}
          </Button>
        </Box>
      </Panel>

      {isDrinkDialogVisible && honeyShotToDrink && (
        <DrinkHoneyShotPopup
          honeyShot={honeyShotToDrink}
          isOpen
          onClose={setIsDrinkDialogVisible.off}
          onSuccess={handleDrinkSuccess}
        />
      )}

      {isPrizePopupVisible && drunkHoneyShot && (
        <PrizePopup
          honeyShot={drunkHoneyShot}
          isOpen
          onClose={handleClosePopups}
        />
      )}

      {isFailurePopupVisible && drunkHoneyShot && (
        <FailurePopup
          honeyShot={drunkHoneyShot}
          isOpen
          onClose={handleClosePopups}
        />
      )}

      {isConsolationPrizePopupVisible && drunkHoneyShot && (
        <ConsolationPrizePopup
          honeyShot={drunkHoneyShot}
          isOpen
          onClose={handleClosePopups}
        />
      )}

      {isHoneyShotDetailsPopupVisible && drunkHoneyShot && (
        <HoneyShotDetailsPopup
          honeyShot={drunkHoneyShot}
          isOpen
          onClose={setIsHoneyShotDetailsPopupVisible.off}
        />
      )}
    </>
  );
};

export default HoneyShotsContainer;
