import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  VStack,
} from '@chakra-ui/react';
import { useConnect } from 'wagmi';
import Panel from '../components/Panel';
import config from '../constants/config';
import usePageTracking from '../hooks/usePageTracking';
import useSnackbarRpcError from '../hooks/useSnackbarRpcError';
import useTranslate from '../hooks/useTranslate';

const ConnectPage = () => {
  const translate = useTranslate();
  const { connectors, connect, error } = useConnect();

  usePageTracking();
  useSnackbarRpcError(error);

  return (
    <>
      <Container
        display="flex"
        pt="12"
        pb="12"
        maxW="sm"
        flex="1"
        alignItems="center"
        h="full"
      >
        <Box w="full">
          <Image
            src={`${config.urls.publicUrl}/connect-logo.svg`}
            w="120px"
            h="auto"
            mb="255px"
            ml="auto"
            mr="auto"
            pointerEvents="none"
            zIndex="1"
          />

          <Panel
            p="8"
            bg="dark.800"
            position="relative"
            pt="10"
            pb="12"
            w="full"
          >
            <Image
              src={`${config.urls.publicUrl}/connect-illustration.png`}
              w="300px"
              h="auto"
              position="absolute"
              top="-225px"
            />

            <Heading fontSize="xl" w="full" textAlign="center" mb="6" mt="1">
              {translate('connect:title')}
            </Heading>

            <VStack w="full" align="stretch" spacing="3">
              {connectors.map(connector => (
                <Button
                  key={connector.id}
                  onClick={() => connect({ connector })}
                  isDisabled={!connector.ready}
                  size="lg"
                  w="full"
                >
                  {translate('connect:button:label', {
                    connector:
                      connector.name === 'Injected'
                        ? 'MetaMask'
                        : connector.name,
                  })}
                </Button>
              ))}
            </VStack>
          </Panel>
        </Box>
      </Container>
    </>
  );
};

export default ConnectPage;
