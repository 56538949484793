import React from 'react';
import { Center, CenterProps } from '@chakra-ui/react';

type IconCoverProps = CenterProps;

const IconCover = (props: IconCoverProps) => (
  <Center
    w="76px"
    h="76px"
    borderRadius="full"
    bg="dark.700"
    color="primary.500"
    {...props}
  />
);

export default IconCover;
